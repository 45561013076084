// Generated by Framer (062fff9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jz70P7S3y"];

const variantClassNames = {jz70P7S3y: "framer-v-1exhjjz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; email?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "jz70P7S3y", email: Q40FJQUgE = "Email", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jz70P7S3y", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-27BVp", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1exhjjz", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jz70P7S3y"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(227, 227, 227)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}}><motion.div className={"framer-4b5656"} data-framer-name={"Frame 2419"} layoutDependency={layoutDependency} layoutId={"EhiaJ7vQL"}><Text __fromCanvasComponent alignment={"left"} className={"framer-wm03gp"} data-framer-name={"Placeholder"} fonts={["GF;Inter Tight-regular"]} layoutDependency={layoutDependency} layoutId={"ta5BwunIa"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Email</span><br></span></span>"} style={{"--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0em", "--framer-line-height": "22px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(136, 136, 136)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={Q40FJQUgE} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-27BVp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-27BVp .framer-1pe86zb { display: block; }", ".framer-27BVp .framer-1exhjjz { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 17px 16px 17px 16px; position: relative; width: 330px; will-change: transform; }", ".framer-27BVp .framer-4b5656 { align-content: flex-start; align-items: flex-start; display: flex; flex: 1 0 0px; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1px; }", ".framer-27BVp .framer-wm03gp { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-27BVp .framer-1exhjjz, .framer-27BVp .framer-4b5656 { gap: 0px; } .framer-27BVp .framer-1exhjjz > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-27BVp .framer-1exhjjz > :first-child { margin-left: 0px; } .framer-27BVp .framer-1exhjjz > :last-child { margin-right: 0px; } .framer-27BVp .framer-4b5656 > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-27BVp .framer-4b5656 > :first-child { margin-top: 0px; } .framer-27BVp .framer-4b5656 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 330
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Q40FJQUgE":"email"}
 */
const FramerxZ83gbfMe: React.ComponentType<Props> = withCSS(Component, css, "framer-27BVp") as typeof Component;
export default FramerxZ83gbfMe;

FramerxZ83gbfMe.displayName = "Input _ without label";

FramerxZ83gbfMe.defaultProps = {height: 56, width: 330};

addPropertyControls(FramerxZ83gbfMe, {Q40FJQUgE: {defaultValue: "Email", displayTextArea: false, placeholder: "Email", title: "Email", type: ControlType.String}} as any)

addFonts(FramerxZ83gbfMe, [{family: "Inter Tight", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/xZ83gbfMe:default", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf", weight: "400"}])